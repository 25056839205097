import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import logo from '../../assets/images/logo.png';
import './Navbar.scss';

const Navbar = () => {
    const location = useLocation();
    const [isHome, setIsHome] = useState(false);

    useEffect(() => {
        if (location.pathname === '/') {
            setIsHome(true);
        } else {
            setIsHome(false);
        }
    }, [location.pathname]);

    return (
        <nav className="navbar">
            <ul className="navbar__nav-items">
                <li className="navbar__nav-item">
                    { !isHome && 
                        <Link className="navbar__nav-link" to="/">
                            <NavigateBeforeIcon />
                            <span className="navbar__nav-link-text">Etusivu</span>
                        </Link>
                    }
                </li>
                <li className="navbar__nav-item">
                    <Link to="/">
                        <img className="navbar__logo" src={ logo } alt="Sanuappi logo, linkki etusivulle." />
                    </Link>
                </li>
                <li className="navbar__nav-item">
                    <Link className="navbar__nav-link" to="/info">
                        <InfoIcon />
                        <span className="navbar__nav-link-text">Info</span>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;