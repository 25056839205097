import React from 'react';
import './Message.scss';

const Message = ({ message }) => {

    return (
        <div className="message">
            <div className="message__icon"></div>
            <div className="message__text">
                { message }
            </div>
            <div className="message__close"></div>
        </div>
    )
};

export default Message;