import React from 'react';
import './Privacy.scss';

const Privacy = () => {

    return (
        <div className="privacy content-container">
            <h1>Tietosuoja</h1>
            <div>
                <h2>Tiivistelmä</h2>
                <p>
                    Sovelluksen (Sanuappi) käyttäjien tietosuoja on meille (Ville Talus & Salla Talus) erittäin tärkeää.
                    Sovellus ei itsessään kerää sen käyttäjästä, tai käytöstä, mitään tietoja.
                    Sovellus ja sen käyttämät kolmansien osapuolien työkalut sekä palvelut ovat GDPR yhteensopivat.

                    Sovellus ei sisällä mainontaa.
                </p>

                <h2>Tavoitteet</h2>
                <p>Tavoitteenamme on, että sovelluksen käyttäjästä ja käytöstä kerätään tietoja niin vähän kuin mahdollista.</p>

                <h2>Evästeet</h2>
                <p>Sovellus ei itsessään käytä evästeitä, mutta jos siirryt sovelluksesta kolmannen osapuolen palveluun, on mahdollista, että kolmas osapuoli haluaa käyttää evästeitä.</p>

                <h2>Henkilötiedot</h2>
                <p>Tavoitteenamme on, että sovelluksen käyttäminen onnistuu ilman, että sen käyttäjästä kerätään tarpeettomasti henkilötietoja.
                    Sovellus ei itse kerää mitään tietoja sen käyttäjistä, eikä sen käytöstä.

                    Sovelluksen kehittämiseen käytettävät kolmansien osapuolien työkalut kuitenkin keräävät teknisiä tietoja, esimerkiksi analytiikkaa varten.
                    Tällaisia tietoja ovat IP-osoite ja sijaintitiedot.

                    Laitteen tiedot, käyttötiedot ja muut
                    Sovellus ei itse kerää mitään laitteeseen tai sovelluksen käyttöön liittyviä tietoja.

                    Sovelluksen kehittämiseen käytettävät kolmansien osapuolien työkalut kuitenkin keräävät erilaisia tietoja, joskin minimaalisen määrän.
                    Nämä tiedot voidaan jakaa kahteen kategoriaan:
                    <ol>
                        <li>
                            Sovelluksen ominaisuuksiin ja toiminnallisuuksiin liittyvät tiedot: Laitteen push-token, jota käytetään esimerkiksi push-notifikaatioiden lähettämiseen. Lisäksi iOS-laitteiden osalta kerätään IDFA, jota kolmasien osapuolten sovelluskehitystyökalut käyttävät laitteen yksilöintiin.
                        </li>
                        <li>
                            Analytiikkaan, lokien tallentamiseen, tietokantoihin ja välimuistiin liittyvät tiedot, kuten IP-osoite.
                        </li>
                    </ol>

                    Näistä internet-osoitteista löydät tarkempia tietoja kolmansien osapuolten tietojen keruusta ja tietosuojasta:
                    <a href="https://expo.io/privacy">https://expo.io/privacy</a>
                    <a href="https://expo.io/privacy-explained">https://expo.io/privacy-explained</a>
                    <a href="https://docs.expo.io/distribution/app-stores/#ios-specific-guidelines">
                        https://docs.expo.io/distribution/app-stores/#ios-specific-guidelines
                    </a>
                    <a href="https://blog.branch.io/how-to-submit-an-app-that-uses-an-idfa-to-the-app-store/">
                        https://blog.branch.io/how-to-submit-an-app-that-uses-an-idfa-to-the-app-store/
                    </a>
                </p>

                <h2>Kohderyhmä</h2>
                <p>
                    Sovelluksen kohderyhmää ovat puheterapeutit sekä yli 18-vuotiaat henkilöt, joilla on kielellisiä haasteita.
                    Sovellus ei ole tarkoitettu alle 18-vuotiaille.
                </p>

                <h2>Muutokset</h2>
                <p>
                    Tietosuojakäyntäntö on voimassa 21.5.2021 alkaen.
                    Sovelluksen tietosuojakäytännöt voivat ajoittain muuttua. Muutoksesista ilmoitetaan tässä kohdassa.
                </p>

                <h2>Lisätiedot</h2>
                <p>
                    Saat lisätietoja sovelluksesta olemalla yhteydessä: sanuappi.suomi@gmail.com.
                </p>
            </div>
        </div>
    );
};

export default Privacy;