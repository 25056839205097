import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import './Layout.scss';
import Home from '../../pages/Home/Home';
import Privacy from '../../pages/Privacy/Privacy';
import Info from '../../pages/Info/Info';

const Layout = () => {
    const onlyInfo = false;

    return (
        <div className="layout">
          <Route exact path="/">
            {onlyInfo ? <Redirect to="/info" /> : <Home />}
          </Route>
          <Route exact path="/info">
            <Info />
          </Route>
          <Route path="/info/tietosuoja">
            <Privacy />
          </Route>
        </div>
    );
};

export default Layout;