import React, { useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import Layout from './components/Layout/Layout';
import Message from './components/Message/Message';

const App = () => {
  const [isOpen] = useState(false);

  return (
    <Router>
      <header>
        <Navbar />
      </header>
      <main id="main-content" className="main-container">
        { isOpen && <Message message="Hi"/>}
        <Switch>
          <Layout />
        </Switch>
      </main>
    </Router>
  )
}

export default App;
