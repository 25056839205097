import React from 'react';
import Content from '../../components/Content/Content';
import iosBadge from '../../assets/images/black.svg';
import './Home.scss';

const Home = () => {
  return (
    <Content>
      <div>
        <h1 className="visually-hidden">Sanuappi</h1>
      </div>
      <div>
        <h2>Lataa Sanuappi sovelluskaupasta</h2>
        <p>Sanuappi on saatavilla Android- ja iOS-laitteille sovelluskaupoista.</p>
        <div className="d-flex">
          <a
            className="home__download-link" 
            href="https://apps.apple.com/fi/app/sanuappi/id1573163247?itsct=apps_box_link&itscg=30200"
            target="_blank"
            rel="noreferrer"
            >
              <img 
                className="home__download-badge home__download-badge--ios" 
                src={ iosBadge } alt="Lataa App Storesta"
              />
          </a>
          <a 
            className="home__download-link"
            href='https://play.google.com/store/apps/details?id=fi.villejasalla.sanuappi&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            target="_blank"
            rel="noreferrer"
          >
              <img
                className="home__download-badge home__download-badge--android"
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/fi_badge_web_generic.png"/>
          </a>
        </div>
        <div>
          <h2>Tulossa - Selaimella käytettävä demo</h2>
          <p>Sovelluksesta on tulossa syksyn aikana selaimella käytettävä maksuton demoversio.</p>
        </div>
      </div>
    </Content>
  );
};

export default Home;
